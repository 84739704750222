import React from "react"
import Layout from '../components/global/layout.js'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Helmet } from "react-helmet"
import imprintStyles from '../style/imprint.module.scss'



const PrivacyPage = () => {

    return (

        <Layout hide>

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <title> Spherical Elephant | Privacy Policy </title>
                <meta name="description" content="Spherical Elephant GmbH | Schegargasse 9/16, 1190 Wien, Österreich | Full-stack Design & Development"></meta>
            </Helmet>
            <div className={imprintStyles.headerbar}>
                <h1 className={imprintStyles.title}>
                    <div className={imprintStyles.privacyTitle}>
                        <div><FormattedMessage id="Privacy.title.1" /></div>
                        <div><FormattedMessage id="Privacy.title.2" /></div>
                    </div>
                </h1>
            </div>
            <div className={imprintStyles.privacy}>
                <div>
                    <p><FormattedMessage id="Privacy.p.1" /></p>
                    <h2><FormattedMessage id="Privacy.h.1" /></h2>
                    <p><FormattedMessage id="Privacy.p.2" /></p>
                    <p><FormattedMessage id="Privacy.p.3" /></p>
                    <h2><FormattedMessage id="Privacy.h.2" /></h2>
                    <p><FormattedMessage id="Privacy.p.4" /></p>
                    <h2><FormattedMessage id="Privacy.h.3" /></h2>
                    <p><FormattedMessage id="Privacy.p.5" /></p>
                    <h2><FormattedMessage id="Privacy.h.4" /></h2>
                    <p><FormattedMessage id="Privacy.p.6" /></p>
                    <h2><FormattedMessage id="Privacy.h.5" /></h2>
                    <p><FormattedMessage id="Privacy.p.7" /></p>
                </div>
            </div>


        </Layout>


    )
}

export default PrivacyPage
